(function ($) {
    'use strict';

    Drupal.behaviors.fbcEloqua = {
        attach: function (context, settings) {
            $('body', context).once('fbcEloqua').each(function () {

                //Replace the Pantheon stripped parameters
                function getQueryParams(qs) {
                    qs = qs.split("+").join(" ");
                    var params = {}, tokens, re = /[?&]?([^=]+)=([^&]*)/g;
                    while (tokens = re.exec(qs)) {
                        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
                    }
                    return params;
                }
                var query_params = getQueryParams(document.location.search);

                for (var key in query_params) {
                    if (key.indexOf('utm_') === 0 || key === 'cid' || key === 'rc' || key === 'cra') {
                        $.sessionStorage(key, query_params[key]);
                    }
                }

                if ($('.webform-client-form').length) {

                    $('.webform-client-form').each(function() {
                        var iWantTo = $(this).find('input[name="submitted[i_would_like_to]"]').val();
                        // Set default product interest in hidden description_4 field.
                        var prodQuote = "This customer is interested in the product(s): " + $(this).find('input[name="submitted[list_all_products_of_interest]"]').val();
                        $(this).find('input[name="submitted[description_4]"]').val(prodQuote);

                        function description4(formElement) {
                          var protectPlus = $(formElement).find('input[name="submitted[protectplus][true]"]').is(':checked');
                          var prodInterest = $(formElement).find('input[name="submitted[list_all_products_of_interest]"]').val();

                          if (protectPlus) {
                            var protectAndProducts = "This customer wants details about a Protect+ plan. " + " They are also interested in the product(s): " + prodInterest;
                          } else {
                            var protectAndProducts = "This customer is interested in the product(s): " + prodInterest;
                          }
                          $(formElement).find('input[name="submitted[description_4]"]').val(protectAndProducts);
                        }

                        $(this).find('input[name="submitted[list_all_products_of_interest]"]').change(function() {
                          description4(this.form);
                        });

                        $(this).find('input[name="submitted[protectplus][true]"]').change(function() {                         
                          description4(this.form);
                        });

                        // Description 2 is the iWantTo field.
                        // Description 4 is the product list or name field.
                        // Update description_2 based on "I want to" choice selected.
                        $(this).find('input[type=radio][name="submitted[i_would_like_to]"]').change(function() {
                            var iWantToText = $('label[for="' + $(this).attr('id') + '"]').text();
                            $(this.form).find('input[name="submitted[description_2]"]').val(iWantToText);
                        });
                        $(this).find('input[name="submitted[campaign_response_activity]"]').val(iWantTo);
                        $(this).find('input[name="submitted[lp_url]"]').val(window.location.href);
                        $(this).find('input[name="submitted[utm_campaign]"]').val($.sessionStorage('utm_campaign'));
                        $(this).find('input[name="submitted[utm_medium]"]').val($.sessionStorage('utm_medium'));

                        const mktingParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_domain', 'cid', 'rc', 'cra'];
                        for(var i = 0; i < mktingParams.length; i++){
                            var mktingParam = mktingParams[i];

                            if ($.sessionStorage(mktingParam)) {
                                if(mktingParam === 'rc') {
                                    $(this).find('input[name="submitted[response_code]"]').val($.sessionStorage(mktingParam));
                                } else if (mktingParam === 'cra') {
                                    $(this).find('input[name="submitted[campaign_response_activity]"]').val($.sessionStorage(mktingParam));
                                } else {
                                    $(this).find('input[name="submitted[' + mktingParam +']"]').val($.sessionStorage(mktingParam));
                                }
                            }
                        }
                    });
                }
            });
        }
    };

    // This function will copy content to the clipboard.
    function copyUrl(e) {

        var copyText = this.nextElementSibling;
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        /* Alert the copied text */
        alert("Here you go! This is what is in your clipboard: \n\n" + textArea.value);
        textArea.remove();
        e.preventDefault();

    }

    Drupal.behaviors.fbc_copyUrls = {
        attach: function (context, settings) {

            if (('view-fbc-attribution-urls').length) {
                var triggers = document.getElementsByClassName("copyTrigger");

                for(var i = 0; i < triggers.length; i++){
                    triggers[i].addEventListener("click", copyUrl);
                }
            }
        }
    };

    // Hide/show form on blog pages.
    Drupal.behaviors.fbc_formOnBlog = {
        attach: function (context, settings) {
                if ($('.node-type-blog-post').length && $('.view-fbc-blog-posts').length){
                    $( '.learn-more-toggle' ).click(function() {

                        if ($('.hidden-form').hasClass('inactive')){
                            $('.hidden-form').removeClass('inactive');
                            $('.hidden-form').addClass('active');
                            $('.form-intro').addClass('inactive');
                            $('.form-intro').removeClass('active');

                        }
                        else {
                            $('.hidden-form').addClass('inactive');
                            $('.hidden-form').removeClass('active');

                        }
                    });
                };
            }
        };

        // Hide/show Buy|quote form on all pages.
        Drupal.behaviors.fbc_modalForm = {
            attach: function (context, settings) {
              if ($("#block-webform-client-block-16").length) {
                $(".webform-client-form-16").addClass("inactive");

                // Move the webform from the footer to the header.
                $(".region-navigation").append(
                  $("#block-webform-client-block-16")
                );
                $("#block-webform-client-block-16").append(
                  '<div class="webform-modal-overlay" style="display: none; z-index: 1"></div>'
                );
                $("#block-webform-client-block-16 .field-name-title").addClass(
                  "button"
                );

                var closeButton =
                  '<div><p class="button button-secondary close-button">Close</p></div>';

                $(
                  "#block-webform-client-block-16 .webform-confirmation"
                ).append(closeButton);

                $("#block-webform-client-block-16 .field-name-title").click(
                  function () {
                    if ($(".webform-client-form-16").hasClass("inactive")) {
                      $(".webform-client-form-16").removeClass("inactive");
                      $(".webform-client-form-16").addClass("active");
                      $(".field-name-title").removeClass("inactive");
                      $(".field-name-title").addClass("active");
                      $(
                        "#block-webform-client-block-16 .field-name-title h3"
                      ).replaceWith("<h3>X</h3>");
                      $(".webform-modal-overlay").css("display", "block");
                      $(".intercom-lightweight-app").css("z-index", "0");
                      iFrameResize({}, '.webform-share-iframe');
                    } else {
                      $(".webform-client-form-16").addClass("inactive");
                      $(".webform-client-form-16").removeClass("active");
                      $(
                        "#block-webform-client-block-16 .field-name-title"
                      ).addClass("inactive");
                      $(
                        "#block-webform-client-block-16 .field-name-title"
                      ).removeClass("active");
                      $(
                        "#block-webform-client-block-16 .field-name-title h3"
                      ).replaceWith("<h3>Contact Sales</h3>");
                      $(".webform-modal-overlay").css("display", "none");
                    }
                  }
                );

                function hideForm() {
                  $(".webform-client-form-16").addClass("inactive");
                  $(".webform-client-form-16").removeClass("active");
                  $(".field-name-title").addClass("inactive");
                  $(".field-name-title").removeClass("active");
                  $(".webform-modal-overlay").css("display", "none");
                  $(
                    "#block-webform-client-block-16 .field-name-title h3"
                  ).replaceWith("<h3>Contact Sales</h3>");
                  if ($(".webform-confirmation").length) {
                    $(".webform-confirmation").slideUp();
                  }
                }
                $("#block-webform-client-block-16 .cancel-button").click(
                  function () {
                    hideForm();
                  }
                );

                $(".webform-modal-overlay").click(function () {
                  hideForm();
                });

                $(".close-button").click(function () {
                  $(".webform-confirmation").slideUp();
                  hideForm();
                });
              }

              // FHS-FORMS iframe Contact Sales.
              if ($("#block-block-42").length) {
                $(".fhs-forms-iframe").addClass("inactive");
                // Move the webform from the footer to the header.
                $(".region-navigation").append($("#block-block-42"));
                $("#block-block-42").append(
                  '<div class="webform-modal-overlay" style="display: none; z-index: 1"></div>'
                );
                $("#block-block-42 .fhs-forms-iframe-btn").addClass("button");
                var closeButton =
                  '<div><p class="button button-secondary close-button">Close</p></div>';
                $("#block-block-42 .webform-confirmation").append(closeButton);
                $("#block-block-42 .fhs-forms-iframe-btn").click(function () {
                  if ($(".fhs-forms-iframe").hasClass("inactive")) {
                    $(".fhs-forms-iframe").removeClass("inactive");
                    $(".fhs-forms-iframe").addClass("active");
                    $(".fhs-forms-iframe-btn").removeClass("inactive");
                    $(".fhs-forms-iframe-btn").addClass("active");
                    $("#block-block-42 .fhs-forms-iframe-btn h3").replaceWith(
                      "<h3>X</h3>"
                    );
                    $(".webform-modal-overlay").css("display", "block");
                    $(".intercom-lightweight-app").css("z-index", "0");
                  } else {
                    $(".fhs-forms-iframe").addClass("inactive");
                    $(".fhs-forms-iframe").removeClass("active");
                    $("#block-block-42 .fhs-forms-iframe-btn").addClass(
                      "inactive"
                    );
                    $("#block-block-42 .fhs-forms-iframe-btn").removeClass(
                      "active"
                    );
                    $("#block-block-42 .fhs-forms-iframe-btn h3").replaceWith(
                      "<h3>Contact Sales</h3>"
                    );
                    $(".webform-modal-overlay").css("display", "none");
                  }
                });

                function hideForm() {
                  $(".fhs-forms-iframe").addClass("inactive");
                  $(".fhs-forms-iframe").removeClass("active");
                  $(".fhs-forms-iframe-btn").addClass("inactive");
                  $(".fhs-forms-iframe-btn").removeClass("active");
                  $(".webform-modal-overlay").css("display", "none");
                  $("#block-block-42 .fhs-forms-iframe-btn h3").replaceWith(
                    "<h3>Contact Sales</h3>"
                  );
                  if ($(".webform-confirmation").length) {
                    $(".webform-confirmation").slideUp();
                  }
                }
                $("#block-block-42 .cancel-button").click(function () {
                  hideForm();
                });

                $(".webform-modal-overlay").click(function () {
                  hideForm();
                });

                $(".close-button").click(function () {
                  $(".webform-confirmation").slideUp();
                  hideForm();
                });
              }
            }
        };

  }(jQuery));
